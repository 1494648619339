/* Provide sufficient contrast against white background */
.big-checkbox {
    transform: scale(1.2);
    margin-top: 2em;
}
.big-checkbox-wrapping{
    padding-left:0;
    padding-right:0;
}
.card-footer {
    text-align: right;
}

.form-floating > label{
    left:10px;
    margin-top:0.75em;
}
.rounded-corners{
    border-radius:25px !important;
}

.homeBtns {
    border: 1px solid #e7e7e7 !important;
    background-color: #30a1e3 !important;
    border-radius: 25px !important;
    font-weight: 600;
    padding-block: 10px;
}
.homeBtns:hover {
    background-color: #2682c4 !important;
}
.first-connection-btn {
    background-color: #0080cb !important
}

.btn-home-booking {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}
.btn-with-img {
    border: hidden;
}

.btn-with-img > img {
    height: 20px;

}
.button-a-style {
    border-width: 0;
    background-color: transparent;
}

/*  NAVBAR  */
a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
.navbar-img {
    height: 3vh;
}

/*  LOGIN   */
.vg-pattern {
    background-image: url(//framework.citybreakcdn.com/0.7-beta/brands/bg-login.png);
    background-color: #30a1e3;
}

.login-container {
    margin-top: 10em;
}

.login-logo{
    margin-top:50px;
    margin-left:20px;
    width: 30vh;
}

/*  BOOKING   */
.btn-moreTypes{
    background-color:white !important;
}
.page-item > a:hover {
    background-color: dodgerblue;
    color: white;
    cursor:pointer;
}

/*  USER    */
.user-main{
    padding-bottom: 2vh;
}
.user-main > a {
    margin-right: 0;
    width: 18vw;
    background-color: rgb(38, 156, 255) !important;
    border: 1px solid #e7e7e7 !important;
    font-weight: 500;
}
.user-main > a:hover {
    border-color: #4d82d1 !important;
}
.btns-user {
    background-color: #30a1e3 !important;
    border: 1px solid #e7e7e7 !important;
}
.btns-user:hover {
    border-color: #4d82d1 !important;
}
.user-img{
    height: 25px;
}
.p-table{
    margin-bottom:0;
}
table{
    margin-bottom:0 !important;
}
.hoverMouseEffect:hover {
    cursor: pointer;
}

/*  MODEL   */
.btns-champs-auto > button{
    margin-bottom:1vh;
    margin-inline: 2px;
}
.btns-champs-auto {
    padding-inline: 10px;
}
.more-info:hover {
    cursor: help;
}
.hover-modal {
    position: absolute;
    margin-top: 40vh;
    margin-left: 10vw;
    border: solid 3px #cbcbcb;
    background-color: rgb(38, 156, 255);
    color: white;
    border-radius: 10px;
    width: auto;
}
    .hover-modal > h4 {
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
.overlay-container{
    position:fixed;
}
.small-width-td{
    width: 100px;
}
.btn-foot-model {
    padding-bottom: 3em;
    margin-top: 1em;
}
.btn-foot-model > *{
    margin-right: 1em;
}
.invert-color {
    filter: invert(100%);
}

/*  - Text chat buble CSS - Generated by ChatGPT ^^ (with a few changes)*/
.bubble-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.bubble {
    position: relative;
    max-width: 100%;
    padding: 1rem;
    border-radius: 1rem;
    margin: 1rem 0;
}

.bubble:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 10px solid transparent;
}

.mine {
    align-self: flex-end;
    background-color: rgb(38, 156, 255);
    color: white;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.mine:before {
    border-left-color: transparent;
    border-top-color: rgb(38, 156, 255);
    right: 7px;
    bottom: -17px;
}


/* Schedule */
.schedule-table-parent{
    height:80vh;
}

/* MUI override */
.MuiInputLabel-root {
    font-size: 0.9rem !important;
}


/* Loading Page */
.loading-screen{
    min-height: 100vh;
}

/* TOAST */
.toast-container {
    
    text-overflow: ellipsis;
}

/* PhoneInput */
.phone-entry-input-group{
    min-width: 350px;
}